<template>
    <user-dashboard-layout>
        <v-card
            elevation="2"
            outlined
        >
            <v-row justify="start">
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-5">
                        <v-select
                            :label="$t('general.select-a-shop-mode')"
                            :items="shop_modes"
                            v-model="select"
                            item-text="title"
                            item-value="id"
                            outlined
                        ></v-select>
                    </v-card-text>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-5">
                        <v-select
                            :label="$t('general.time-zone')"
                            v-model="select_timezone"
                            :items="timezones"
                            item-text="timezone"
                            outlined
                        ></v-select>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.number-of-orders-per-hour')"
                            type="number"
                            v-model="orders_max_per_hour"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.number-of-add-items-per-hour')"
                            type="number"
                            v-model="max_add_items_per_hour"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.processing-time-in-hours')"
                            type="number"
                            v-model="order_processing_time_hours"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.mollie-key')"
                            type="text"
                            v-model="mollieKey"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-select
                            :label="$t('general.select-a-localization')"
                            :items="locale_items"
                            v-model="locale"
                            item-text="state"
                            item-value="abbr"
                            @change="setLocale"
                            outlined
                        ></v-select>
                    </v-card-text>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.msg-orders-per-hour-is-exceeded')"
                            type="text"
                            v-model="msg_orders_per_hour_is_exceeded"
                            outlined
                            required
                        ></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pl-8 mb-5">
                    <h3 class="payment-methods__h3">Payment methods</h3>
                    <div class="payment-methods__wrap">
                        <div class="payment-methods__pickup-wrapper">
                            <h4>Pickup</h4>
                            <v-switch label="Cash" v-model="pickup_cash_enabled"></v-switch>
                            <v-switch label="Mollie" v-model="pickup_mollie_enabled"></v-switch>
                        </div>
                        <div class="payment-methods__delivery-wrapper">
                            <h4>Delivery</h4>
                            <v-switch label="Cash" v-model="delivery_cash_enabled"></v-switch>
                            <v-switch label="Mollie" v-model="delivery_mollie_enabled"></v-switch>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pl-8 mb-5">
                    <h3 class="textarea__h3">Delivery details email text</h3>
                    <textarea cols="30" rows="3" v-model="delivery_details_email_text"
                              class="form-settings__textarea"></textarea>
                    <span class="textarea__border-bottom"></span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pl-8">
                    <v-switch
                        class="py-0 mt-0"
                        v-model="deliver_orders"
                        hide-details
                        inset
                        color="#1E5CBA"
                        :label="$t('general.we-deliver-orders')"
                    ></v-switch>
                </v-col>
                <v-col cols="12" class="pl-8">
                    <v-switch
                        class="py-0 mt-0"
                        v-model="is_only_pickup"
                        hide-details
                        inset
                        color="#1E5CBA"
                        :label="$t('general.only-pickup')"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pl-8">
                    <v-switch
                        class="py-0 mt-0"
                        v-model="vending_enabled"
                        hide-details
                        inset
                        color="#1E5CBA"
                        :label="$t('general.vending')"

                    ></v-switch>
                </v-col>
            </v-row>
            <v-row v-if="deliver_orders">
                <v-col cols="12" class="pl-8">
                    <v-switch
                        class="py-0 mt-0"
                        v-model="is_vat_shown"
                        hide-details
                        inset
                        color="#1E5CBA"
                        :label="$t('general.vat-number')"
                    ></v-switch>
                </v-col>
                <v-col cols="12" class="py-0">
                    <v-card-title>{{ $t('general.delivery-desc') }}</v-card-title>
                </v-col>
                <v-col cols="12" class="mx-4 py-0">
                <textarea cols="30" rows="3"
                          v-model="delivery_desc"
                          class="form-settings__textarea"
                ></textarea>
                </v-col>
                <v-col cols="12" class="py-0">
                    <v-card-title>{{ $t('general.delivery-price') }}</v-card-title>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.zipcode')"
                            type="number"
                            v-model="deliver_textfields.zipcode"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :label="$t('general.cost')"
                            type="number"
                            v-model="deliver_textfields.cost"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
                <v-col cols="12" class="pl-8 mb-5">
                    <v-switch
                        class="py-0 mt-0"
                        v-model="other_zipcodes"
                        hide-details
                        inset
                        color="#1E5CBA"
                        :label="$t('general.deliver-to-other-zipcodes')"
                    ></v-switch>
                </v-col>
                <div v-if="other_zipcodes" class="ml-4">
                    <v-row v-for="(textfields, i) in other_textfields" :key="i">
                        <v-col cols="2" class="py-0">
                            <v-card-text class="py-0">
                                <v-text-field
                                    :label="$t('general.zipcode')"
                                    type="number"
                                    v-model="textfields.zipcode"
                                    :backgroundColor="highlightDelivery(textfields)"
                                    outlined
                                ></v-text-field>
                            </v-card-text>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-card-text class="py-0">
                                <v-text-field
                                    :label="$t('general.min-order-cost')"
                                    type="number"
                                    @input="textfields.highlight = true"
                                    v-model="textfields.min_order_cost"
                                    :backgroundColor="highlightDelivery(textfields)"
                                    outlined
                                ></v-text-field>
                            </v-card-text>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-card-text class="py-0">
                                <v-text-field
                                    :label="$t('general.cost')"
                                    type="number"
                                    v-model="textfields.cost"
                                    :backgroundColor="highlightDelivery(textfields)"
                                    outlined
                                ></v-text-field>
                            </v-card-text>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-card-text class="py-0 pb-3">
                                <v-btn @click="removeZipcodes(i)" class="error">
                                    {{ $t('general.delete') }}
                                </v-btn>
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-col v-if="other_zipcodes" cols="12" class="py-0">
                        <v-card-text class="py-3">
                            <v-btn outlined style="border: 3px dotted #1E5CBA;" color="primary" @click="addZipcodes()">
                                {{ $t('general.add-zipcodes') }}
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </div>
            </v-row>


            <!-- MAILER SETTINGS -->
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-card-title>{{ $t('general.mailer-settings') }}</v-card-title>
                </v-col>
            </v-row>

            <!-- Mailer From -->
            <v-row>
                <!-- From email -->
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :error-messages="form_errors['mailer.from_email']"
                            :label="$t('general.from') + ' ' + $t('general.email')"
                            type="text"
                            v-model="mailer.from_email"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
                <!-- From name -->
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-text-field
                            :error-messages="form_errors['mailer.from_name']"
                            :label="$t('general.from') + ' ' + $t('general.name')"
                            type="text"
                            v-model="mailer.from_name"
                            outlined
                        ></v-text-field>
                    </v-card-text>
                </v-col>
            </v-row>
            <!-- Mailer Transport -->
            <v-row>
                <v-col cols="6" class="py-0">
                    <v-card-text class="py-3">
                        <v-select
                            :error-messages="form_errors['mailer.transport']"
                            :label="$t('general.transport')"
                            :items="['default','smtp', 'mailgun']"
                            v-model="mailer.transport"
                        ></v-select>
                    </v-card-text>
                </v-col>
            </v-row>

            <!-- Mailer Transport Settings -->
            <div v-if="mailer.transport === 'smtp'">
                <!-- Smtp Config -->
                <v-row>
                    <!-- Smtp host -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.smtp_host']"
                                :label="'Smtp ' + $t('general.host')"
                                type="text"
                                v-model="mailer.smtp_host"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <!-- Smtp port -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.smtp_port']"
                                :label="'Smtp ' + $t('general.port')"
                                type="number"
                                v-model="mailer.smtp_port"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <!-- Smtp username -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.smtp_username']"
                                :label="'Smtp ' + $t('general.username')"
                                type="text"
                                v-model="mailer.smtp_username"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <!-- Smtp password -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.smtp_password']"
                                :label="'Smtp ' + $t('general.password')"
                                type="password"
                                v-model="mailer.smtp_password"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <!-- Smtp encryption -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.smtp_encryption']"
                                :label="'Smtp ' + $t('general.encryption')"
                                type="text"
                                v-model="mailer.smtp_encryption"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                </v-row>
            </div>
            <div v-if="mailer.transport === 'mailgun'">
                <!-- Mailgun Config -->
                <v-row>
                    <!-- Mailgun domain -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.mailgun_domain']"
                                :label="'Mailgun ' + $t('general.domain')"
                                type="text"
                                v-model="mailer.mailgun_domain"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <!-- Mailgun secret -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.mailgun_secret']"
                                :label="'Mailgun ' + $t('general.secret')"
                                type="password"
                                v-model="mailer.mailgun_secret"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                    <!-- Mailgun endpoint -->
                    <v-col cols="6" class="py-0">
                        <v-card-text class="py-3">
                            <v-text-field
                                :error-messages="form_errors['mailer.mailgun_endpoint']"
                                :label="'Mailgun ' + $t('general.endpoint')"
                                type="text"
                                v-model="mailer.mailgun_endpoint"
                                outlined
                            ></v-text-field>
                        </v-card-text>
                    </v-col>
                </v-row>
            </div>

            <v-row>
                <v-col cols="12" class="py-0">
                    <v-card-text class="py-3">
                        <v-btn @click="saveShopSettings" color="primary" x-large>
                            {{ $t('general.save') }}
                        </v-btn>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import {mapActions, mapMutations} from "vuex";
import Settings from '../../../models/Settings';
import {
    formatZipcodesForSave,
    sortZipcodes,
} from "@/helpers/dashboard/shop_settings/shop_settings";
import {cloneDeep} from "lodash";

export default {
    name: "DashboardShopSettings",
    components: {UserDashboardLayout},
    data: () => ({
        select: {id: 1, title: 'Catalog'},
        shop_modes: [
            {id: 1, title: 'Catalog'},
            {id: 2, title: 'Free text'}
        ],
        orders_max_per_hour: '',
        max_add_items_per_hour: '',
        order_processing_time_hours: '',
        zipcode_first: '',
        cost_first: '',
        shop_settings: {},
        select_timezone: {timezone: "CET"},
        mollieKey: null,
        locale: {state: 'English', abbr: 'us'},
        locale_items: [
            {state: 'English', abbr: 'us'},
            {state: 'Dutch', abbr: 'nl'}
        ],
        localeSelect: 'us',
        timezones: [
            {timezone: "CET"}, {timezone: "Europe/Amsterdam"}, {timezone: "Europe/Andorra"}, {timezone: "Europe/Astrakhan"}, {timezone: "Europe/Athens"},
            {timezone: "Europe/Belgrade"}, {timezone: "Europe/Berlin"}, {timezone: "Europe/Bratislava"}, {timezone: "Europe/Brussels"},
            {timezone: "Europe/Bucharest"}, {timezone: "Europe/Budapest"}, {timezone: "Europe/Busingen"}, {timezone: "Europe/Chisinau"},
            {timezone: "Europe/Copenhagen"}, {timezone: "Europe/Dublin"}, {timezone: "Europe/Gibraltar"}, {timezone: "Europe/Guernsey"},
            {timezone: "Europe/Helsinki"}, {timezone: "Europe/Isle_of_Man"}, {timezone: "Europe/Istanbul"}, {timezone: "Europe/Jersey"},
            {timezone: "Europe/Kaliningrad"}, {timezone: "Europe/Kirov"}, {timezone: "Europe/Kyiv"}, {timezone: "Europe/Lisbon"},
            {timezone: "Europe/Ljubljana"}, {timezone: "Europe/London"}, {timezone: "Europe/Luxembourg"}, {timezone: "Europe/Madrid"},
            {timezone: "Europe/Malta"}, {timezone: "Europe/Mariehamn"}, {timezone: "Europe/Minsk"}, {timezone: "Europe/Monaco"},
            {timezone: "Europe/Moscow"}, {timezone: "Europe/Oslo"}, {timezone: "Europe/Paris"}, {timezone: "Europe/Podgorica"},
            {timezone: "Europe/Prague"}, {timezone: "Europe/Riga"}, {timezone: "Europe/Rome"}, {timezone: "Europe/Samara"},
            {timezone: "Europe/San_Marino"}, {timezone: "Europe/Sarajevo"}, {timezone: "Europe/Saratov"}, {timezone: "Europe/Simferopol"},
            {timezone: "Europe/Skopje"}, {timezone: "Europe/Sofia"}, {timezone: "Europe/Stockholm"}, {timezone: "Europe/Tallinn"},
            {timezone: "Europe/Tirane"}, {timezone: "Europe/Ulyanovsk"}, {timezone: "Europe/Vaduz"}, {timezone: "Europe/Vatican"},
            {timezone: "Europe/Vienna"}, {timezone: "Europe/Vilnius"}, {timezone: "Europe/Volgograd"}, {timezone: "Europe/Warsaw"},
            {timezone: "Europe/Zurich"}, {timezone: "Europe/Zagreb"}
        ],
        deliver_orders: false,
        is_only_pickup: false,
        other_zipcodes: false,
        deliver_textfields: {zipcode: '', cost: ''},
        other_textfields: [],
        delivery: [],
        pickup_cash_enabled: false,
        pickup_mollie_enabled: false,
        delivery_cash_enabled: false,
        delivery_mollie_enabled: false,
        delivery_desc: '',
        delivery_details_email_text: '',
        is_vat_shown: false,
        vending_enabled: false,
        msg_orders_per_hour_is_exceeded: '',
        mailer: {},
        form_errors: {},
    }),
    mounted() {
        this.loadShopSettings()
        this.init()
    },
    methods: {
        ...mapActions(["loadShopSettings", "updateShopSettings"]),
        ...mapMutations(["showSnackBar"]),
        async saveShopSettings() {
            this.sortZipcodes();
            if (this.other_zipcodes) {
                this.delivery = []
                let firstItem = []
                firstItem.unshift(this.deliver_textfields)
                this.delivery = firstItem.concat(this.other_textfields)
                let delivery_clone = cloneDeep(this.delivery)
                this.shop_settings[0].delivery = formatZipcodesForSave(delivery_clone)
            } else {
                this.delivery = []
                this.delivery.unshift(this.deliver_textfields)
                this.shop_settings[0].delivery = this.delivery
            }
            this.shop_settings[0].shop_mode = this.select.id ?? this.select
            this.shop_settings[0].orders_max_per_hour = this.orders_max_per_hour
            this.shop_settings[0].max_add_items_per_hour = this.max_add_items_per_hour
            this.shop_settings[0].order_processing_time_hours = this.order_processing_time_hours
            this.shop_settings[0].timezone = this.select_timezone.timezone ?? this.select_timezone
            this.shop_settings[0].mollie_key = this.mollieKey
            this.shop_settings[0].locale = this.localeSelect
            this.shop_settings[0].delivery_enabled = this.deliver_orders
            this.shop_settings[0].is_vat_shown = this.is_vat_shown
            this.shop_settings[0].vending_enabled = this.vending_enabled
            this.shop_settings[0].is_only_pickup = this.is_only_pickup

            this.shop_settings[0].pickup_cash_enabled = this.pickup_cash_enabled
            this.shop_settings[0].pickup_mollie_enabled = this.pickup_mollie_enabled
            this.shop_settings[0].delivery_cash_enabled = this.delivery_cash_enabled
            this.shop_settings[0].delivery_mollie_enabled = this.delivery_mollie_enabled
            this.shop_settings[0].delivery_desc = this.delivery_desc
            this.shop_settings[0].delivery_details_email_text = this.delivery_details_email_text
            this.shop_settings[0].msg_orders_per_hour_is_exceeded = this.msg_orders_per_hour_is_exceeded
            this.shop_settings[0].mailer = this.mailer

            let settings = new Settings(this.shop_settings[0])

            try {
                await settings.save()
            } catch (error) {
                this.form_errors = error.response?.data?.errors;
                this.showSnackBar({color: 'error', timeout: 3000, text: error.response?.data?.message})
            }

            this.loadShopSettings()
        },
        async init() {
            this.shop_settings = await Settings.get()

            this.select = String(this.shop_settings[0].shop_mode) === "1" ? {id: 1, title: 'Catalog'} : {
                id: 2,
                title: 'Free text'
            }
            this.select_timezone = {timezone: this.shop_settings[0].timezone}
            this.orders_max_per_hour = this.shop_settings[0].orders_max_per_hour
            this.order_processing_time_hours = this.shop_settings[0].order_processing_time_hours
            this.max_add_items_per_hour = this.shop_settings[0].max_add_items_per_hour
            this.mollieKey = this.shop_settings[0].mollie_key
            this.localeSelect = this.shop_settings[0].locale
            if (this.shop_settings[0].locale === 'us') {
                this.locale = {state: 'English', abbr: this.shop_settings[0].locale}
            } else {
                this.locale = {state: 'Dutch', abbr: this.shop_settings[0].locale}
            }
            this.localeSelect = this.shop_settings[0].locale
            this.$i18n.locale = this.shop_settings[0].locale
            this.deliver_orders = this.shop_settings[0].delivery_enabled
            this.is_vat_shown = this.shop_settings[0].is_vat_shown
            this.vending_enabled = this.shop_settings[0].vending_enabled
            this.delivery = JSON.parse(this.shop_settings[0].delivery)
            let has_other_zipcodes = false;
            if (this.delivery) {
                this.deliver_textfields.zipcode = this.delivery[0].zipcode
                this.deliver_textfields.cost = this.delivery[0].cost
                if (this.delivery.length > 1) {
                    has_other_zipcodes = true;
                }
            }
            if (has_other_zipcodes) {
                this.other_zipcodes = true
                this.delivery.forEach((item, index) => {
                    if (index !== 0) {
                        this.other_textfields.push(item)
                    }
                })
            }
            this.sortZipcodes();
            this.pickup_cash_enabled = this.shop_settings[0].pickup_cash_enabled
            this.pickup_mollie_enabled = this.shop_settings[0].pickup_mollie_enabled
            this.delivery_cash_enabled = this.shop_settings[0].delivery_cash_enabled
            this.delivery_mollie_enabled = this.shop_settings[0].delivery_mollie_enabled
            this.delivery_desc = this.shop_settings[0].delivery_desc
            this.delivery_details_email_text = this.shop_settings[0].delivery_details_email_text
            this.is_only_pickup = this.shop_settings[0].is_only_pickup
            this.msg_orders_per_hour_is_exceeded = this.shop_settings[0].msg_orders_per_hour_is_exceeded
            this.mailer = this.shop_settings[0].mailer ?? {}
        },
        setLocale(locale) {
            if (locale === 'nl') {
                this.$i18n.locale = 'nl'
                this.localeSelect = 'nl'
            } else {
                this.$i18n.locale = 'us'
                this.localeSelect = 'us'
            }
        },
        addZipcodes() {
            this.other_textfields.push({
                min_order_cost: 0,
                zipcode: '',
                cost: '',
                highlight: true,
            });
        },
        removeZipcodes(index) {
            this.other_textfields.splice(index, 1)
        },
        highlightDelivery(item) {
            if (item.highlight) {
                return 'green lighten-5';
            }
            return undefined;
        },
        sortZipcodes: sortZipcodes,
    }
}
</script>

<style scoped lang="scss">
.v-btn {
    width: 253px;
    height: 56px;
    border-radius: 10px;
}

.locale__header {
    color: rgba(0, 0, 0, .6);
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, .42);
    padding-bottom: 3px;
}

.locale__link .border-bottom span {
    width: 24px;
    height: 24px;
    margin: 5px;
}

.locale__link a span {
    width: 22px;
    height: 22px;
    margin: 5px;
}

.payment-methods__h3 {
    border-top: 1px solid #e0e0e0;
    max-width: 99%;
    min-width: 99%;
    margin-bottom: 30px;
    padding-top: 30px;
}

.textarea__h3 {
    max-width: 99%;
    min-width: 99%;
    margin-bottom: 30px;
    padding-top: 0;
}

.textarea__border-bottom {
    display: inline-block;
    width: 99%;
    padding-bottom: 45px;
    border-bottom: 1px solid #e0e0e0;
}

.payment-methods__wrap {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
    max-width: 99%;

    .payment-methods__pickup-wrapper {
        margin-right: 60px;
    }
}

.form-settings__textarea {
    box-sizing: border-box;
    width: 97%;
    height: 85px;
    background: #FFFFFF;
    border: 1px solid #CECECE;
    border-radius: 5px;
    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding: 9px 16px;
}
</style>
